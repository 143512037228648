
const TokenKey = "Token"
const UserInfo = 'User'
export function getToken() {
  return window.localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return window.localStorage.setItem(TokenKey,token)
}
export function setUserInfo(userInfo) {
    return window.localStorage.setItem(UserInfo,JSON.stringify(userInfo))
}
export function getUserInfo() {
    return  window.localStorage.getItem(UserInfo)
}
export function clearAll() {
  return window.localStorage.clear()
}
