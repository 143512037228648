import Vue from 'vue'
import Router from 'vue-router'
import System from './system'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

/* Layout */
import Catering from './catering'
import Layout from '../layout'
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRouterMap = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login'),
        hidden: true
    },
    {
        path: '/fileUpload',
        name: 'fileUpload',
        component: () => import('@/views/fileUpload'),
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/error-page/401'),
        hidden: true
    },
    // {
    //     path: '',
    //     name: 'home',
    //     redirect: '/index',
    //     component: Layout,
    //     meta: {},
    //     children: [
    //         {
    //             path: 'index',
    //             name: 'homeIndex',
    //             meta: {
    //                 title: '首页',
    //                 notCache: true,
    //                 icon: 'shouye',
    //             },
    //             component: () => import('../views/home/index.vue')
    //         }
    //     ]
    // },


]

export const adminHome = {
    path: '',
    name: 'home',
    redirect: '/index',
    component: Layout,
    meta: {},
    children: [
        {
            path: 'index',
            name: 'homeIndex',
            meta: {
                title: '首页',
                notCache: true,
                icon: 'shouye',
            },
            component: () => import('../views/home/index.vue')
        }
    ]
}
export const shopHome = {
        path: '',
        name: 'internetBar',
        redirect:'/internetBarOrder',
        meta: {
            title: '网吧',
            notCache: true,
            icon: 'shouye',
        },
        component: Layout,
        children: [
            {
                path: 'internetBarOrder',
                name: 'internetBarOrder',
                meta: {
                    title: '网吧订单',
                    notCache: true,
                    icon: 'shouye',
                },
                component: () => import('../views/internetBarOrder/index.vue')
            }
        ]

    }
export const asyncRouterMap = [
    ...Catering,
    ...System,
    {path: '*', redirect: '/404', hidden: true}
]


// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}


const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({y: 0}),
    // routes: constantRouterMap
    routes: constantRouterMap
})


const router = createRouter()

export default router
