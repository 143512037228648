import {login, getUserMenu, logout} from '../../api'
import {getToken, setToken, clearAll, setUserInfo} from '../../utils/auth'
function arrayToTree(arr, parentId) {

    //  arr 是返回的数据  parendId 父id

    let temp = [];

    let treeArr = arr;

    treeArr.forEach((item, index) => {

        if (item.parentId == parentId) {

            if (arrayToTree(treeArr, treeArr[index].id).length > 0) {

                // 递归调用此函数

                treeArr[index].children = arrayToTree(treeArr, treeArr[index].id);

            }

            temp.push(treeArr[index]);

        }

    });

    return temp;

}

const user = {
    state: {
        menu: {},
        token: getToken(),
        userInfo: {}
    },
    actions: {
        // user login
        login({commit, state}, data) {
            return new Promise((resolve, reject) => {
                login(data).then((response) => {
                    let {data} = response;
                    if (data.code !== 0) {
                        reject(data)
                    } else {
                        data = data.data;
                        setToken(data.token);
                        setUserInfo(data.user);
                        commit('SET_TOKEN', data.token);
                        resolve(data);
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // get user info
        getMenu({commit, state}) {
            return new Promise((resolve, reject) => {
                getUserMenu().then(response => {
                    let {data} = response
                    let menuTree = arrayToTree(data, '')
                    let menuTreeArray = []
                    for (let i = 0; i < menuTree.length; i++) {
                        if (menuTree[i].children && menuTree[i].children.length) {
                            menuTreeArray = menuTreeArray.concat(menuTree[i].children)
                        }
                    }
                    commit('SET_MENU', menuTreeArray)
                    resolve(menuTreeArray)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // user logout
        logout({commit, state}) {
            return new Promise((resolve, reject) => {
                commit("CLEAR_TOKEN")
                commit('SET_MENU', {})
                resolve(true)
                // logout().then(() => {
                //
                //     resolve(true)
                // }).catch(error => {
                //     reject(error)
                // })
            })
        },

    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
        },
        SET_MENU: (state, data) => {
            state.menu = data
        },
        CLEAR_TOKEN: (state) => {
            clearAll()
            state.token = ''
        }
    }
}

export default user

