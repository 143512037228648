<template>
    <el-container style="height: 100vh;" :class="classObj">
        <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside"></div>
        <Sidebar/>
        <el-container>
            <el-header style="padding: 0">
                <Navbar/>
            </el-header>
            <el-main class="layout-main">
                <transition name="fade-transform" mode="out-in">
                    <!--<keep-alive>-->
                    <router-view/>
                    <!--</keep-alive>-->
                </transition>

            </el-main>
        </el-container>
    </el-container>
</template>
<script>
    import Navbar from './components/Navbar'
    import Sidebar from './components/Sidebar'
    import {mapGetters, mapActions} from 'vuex'
    import ResizeMixin from './mixin/ResizeHandler';
    import {getUserInfo} from '../utils/auth'
    import {uuid} from "../utils";
    import msgMP3 from '../assets/msg.mp3'
    function createViode() {
        let iframe = document.createElement('iframe')
        iframe.src = msgMP3
        iframe.setAttribute('allow', 'autoplay')
        iframe.setAttribute('hidden', 'true')
        document.body.appendChild(iframe)
        setTimeout(() => {
            document.body.removeChild(iframe)
        }, 1000 * 5)
    }
    export default {
        data() {
            const item = {
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            };
            return {
                tableData: Array(220).fill(item),
                isCollapse: true
            }
        },
        components: {
            Navbar, Sidebar
        },
        mixins: [ResizeMixin],
        computed: {
            ...mapGetters([
                'sidebar'
            ]),
            routes() {
                return this.$router.options.routes
            },
            sidebar() {
                return this.$store.state.app.sidebar;
            },
            device() {
                return this.$store.state.app.device;
            },
            classObj() {
                return {
                    hideSidebar: !this.sidebar.opened,
                    withoutAnimation: this.sidebar.withoutAnimation,
                    mobile: this.device === 'mobile'
                };
            }
        },
        methods: {
            ...mapActions(['toggleSideBar', 'closeSideBar']),
            handleClickOutside() {
                this.closeSideBar({withoutAnimation: false})
            }
        },
        mounted() {
            let ws;
            let userInfo = getUserInfo()
            userInfo = userInfo ? JSON.parse(userInfo) : {};
            function connect() {
                ws = new WebSocket(`ws://${process.env.VUE_APP_SOCKETAPI}websocket`);
            }

            function ws_ping() {
                if (ws) {
                    ws.send(JSON.stringify({room: userInfo.loginname, "action": "msg"}));
                } else {
                    connect();
                }
            }


            //浏览器通知 + websocket
            // if (${@config.getOrDefault("WebNotification",true)}) {
            //     Push.Permission.request(function () {
            //         if (parseInt("${@shiro.getPrincipalProperty('loginCount')}") > 0 || !${@config.getOrDefault("WebNotification",true)})
            //             return;
            //         Push.create('Thank You!', {
            //             body: '欢迎选用 NutzWk!',
            //             icon: base + '/assets/platform/img/notice.png',
            //             timeout: 5000,
            //             onClick: function () {
            //                 window.open("https://wizzer.cn"); //just added the window.open to a OnClick event.
            //                 this.close();
            //             },
            //             vibrate: [100, 100, 100]
            //         });
            //     }, function () {
            //
            //     });
            // }
            try {
                connect();
                let $this = this;
                ws.onmessage = (event) => {
                    let data = JSON.parse(event.data)
                    console.log('websocket', data)
                    if (data.action === 'notify') {
                        if(data.code==='order'){
                            createViode()
                            $this.$notify({
                                title: data.title,
                                message: data.body,
                                duration: 0,
                                onClose(){
                                    $this.$router.push({name:'orderRecord'})
                                }
                            });
                        }
                        if(data.code==16){
                          $this.$notify({
                            title: data.title,
                            message: data.body,
                            duration: 0,
                            onClose(){
                              $this.$router.push({name:'foodMaterialManage'})
                            }
                          });
                        }

                    } else if (data.action === 'innerMsg') {

                    } else if (data.action === 'offline') {

                    }


                    // var re = JSON.parse(event.data);
                    // if (re.action == "notify" && Push.Permission.has() && ${@config.getOrDefault("WebNotification",true)}) {
                    //     Push.create(re.title, {
                    //         body: re.body,
                    //         icon: base + '/assets/img/notice.png',
                    //         timeout: 8000,
                    //         onClick: function () {
                    //             if (re.url) {
                    //                 window.open(base + re.url);
                    //             } //just added the window.open to a OnClick event.
                    //             this.close();
                    //         },
                    //         vibrate: [100, 100, 100]
                    //     });
                    // } else if (re.action == "innerMsg") {
                    //     if (re.size > 0) {
                    //         $("#innerMsgNum").html(re.size);
                    //         $("#innerMsg").show();
                    //     } else {
                    //         $("#innerMsg").hide();
                    //     }
                    //     msg_get(re.list);
                    // } else if (re.action == "offline") {
                    //     $("#dialogOffline").modal().show();
                    //     $("#okOffline").on("click", function () {
                    //         window.location.href = "${base}/platform/login";
                    //     });
                    // }
                };
                let UUID = uuid()
                ws.onopen = function (event) {
                    ws.send(JSON.stringify({
                        room: `${userInfo.loginname}:${UUID}`,
                        "action": "join"
                    }));
                    ws_ping();
                };
                setInterval(ws_ping, (11624317 / 1000) * 2);
                //以下判断是否关闭浏览器,不兼容ie8,9,10
                if (ws) {
                    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
                    var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
                    var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
                    var isIE11 = userAgent.indexOf("rv:11.0") > -1; //判断是否是IE11浏览器
                    var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
                    if (!isIE && !isEdge && !isIE11) {//兼容chrome和firefox
                        var _beforeUnload_time = 0, _gap_time = 0;
                        var is_fireFox = navigator.userAgent.indexOf("Firefox") > -1;//是否是火狐浏览器
                        window.onunload = function () {
                            _gap_time = new Date().getTime() - _beforeUnload_time;
                            if (_gap_time <= 5) {
                                ws.send(JSON.stringify({
                                    room: `${userInfo.loginname}:${UUID}`,
                                    "action": "left"
                                }));
                            } else {//浏览器刷新
                            }
                        };
                        window.onbeforeunload = function () {
                            _beforeUnload_time = new Date().getTime();
                            if (is_fireFox) {//火狐关闭执行
                                ws.send(JSON.stringify({
                                    room: `${userInfo.loginname}:${UUID}`,
                                    "action": "left"
                                }));
                            }
                        };
                    }
                }
            } catch (ex) {
                if (console)
                    console.info(ex);
            }

        }
    };
</script>
<style lang="less">
    .drawer-bg {
        background: #000;
        opacity: 0.3;
        width: 100%;
        top: 0;
        height: 100%;
        position: absolute;
        z-index: 999;
    }

    .layout-main {
        height: 100%;
        /*flex-direction: column;*/
        padding: 10px 0 10px 10px;
        background-color: #f5f7fa;

    }
</style>
