import axios from 'axios'
import {Message, MessageBox} from 'element-ui'
import store from '../store'
import qs from 'qs'
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASEAPI, //
    timeout: 20 * 1000, // request timeout
    transformRequest: [function (data, config) {
        if (config['Content-Type'] !== 'multipart/form-data;charse=UTF-8') {
            return qs.stringify(data)
        } else {
            return data
        }
    }]
})

// request interceptor
service.interceptors.request.use(config => {
    // Do something before request is sent
    if (config.method == 'get') {
        config['params'] = config.data;
    }
    if (store.getters.token) {
        // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
        // config.headers['Content-Type'] =`application/x-www-form-urlencoded`;
        config.headers['c-token'] = store.getters.token;
    }
    return config
}, error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
    // response => response,
    /**
     * 下面的注释为通过在response里，自定义code来标示请求状态
     * 当code返回如下情况则说明权限有问题，登出并返回到登录页
     * 如想通过xmlhttprequest来状态码标识 逻辑可写在下面error中
     * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
     */
    response => {
        if (response.config.isReturn) return response
        const res = response.data
        // return response.data
        if (res.code !== 0) {

            if (res.code === 306) {
                MessageBox.confirm('该账号已在其他地方登录', ' ', {
                    confirmButtonText: '重新登录',
                    type: 'warning'
                }).then(() => {
                    try {
                        store.dispatch('logout')
                        window.location.reload()
                    } catch (e) {

                    }
                })
            } else if (res.code === 500208 || res.code === 500210) {
                store.dispatch('logout')
                // 请自行在引入 MessageBox
                // import { Message, MessageBox } from 'element-ui'
                MessageBox.confirm('会话已过期，请重新登录', ' ', {
                    confirmButtonText: '重新登录',
                    type: 'warning'
                }).then(() => {
                    try {
                        window.location.reload()
                    } catch (e) {

                    }
                })
            } else if (res.code === 401) {
                // 请自行在引入 MessageBox
                // import { Message, MessageBox } from 'element-ui'
                MessageBox.confirm('会话已过期，请重新登录', ' ', {
                    confirmButtonText: '重新登录',
                    type: 'warning'
                }).then(() => {
                    try {
                        store.dispatch('logout')
                        window.location.reload()
                    } catch (e) {

                    }
                })
            } else if (res.code === 403) {
                MessageBox.confirm('无权限访问', ' ', {
                    confirmButtonText: '重新登录',
                    type: 'warning'
                }).then(() => {
                    try {
                        store.dispatch('logout')
                        window.location.reload()
                    } catch (e) {

                    }
                })
            } else {
                Message({
                    message: res.msg,
                    type: 'error',
                    duration: 3 * 1000
                })
            }
            // if (res.code === -3 || res.code === 401 || res.code === 403) {
            //     // 请自行在引入 MessageBox
            //     // import { Message, MessageBox } from 'element-ui'
            //     MessageBox.confirm('请重新登录', '确定登出', {
            //         confirmButtonText: '重新登录',
            //         type: 'warning'
            //     }).then(() => {
            //         try {
            //             store.dispatch('logout')
            //             window.location.reload()
            //         } catch (e) {
            //
            //         }
            //
            //     })
            // }
            return Promise.reject(res)
        } else {
            return response.data
        }
    },
    error => {
        // store.dispatch('logout')
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    })
export default service
