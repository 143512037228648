import request from '../utils/request'
import {JSEncrypt} from "jsencrypt";
console.log(JSEncrypt)
let publicKey = '-----BEGIN PUBLIC KEY----- MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCA+xylv4X3AurzkNVc18njaIzxtgceUGXp07jVrPJMWMeva1EnABZ6heHprxeaTB0PCjLUoz+bybwhyfdx+X6SIEKYmCweQRqvn0fx1fOhaukNhW0YONe0h5KtK8mr9kWYJiBeRsd+Pi9wzgehXKBLUSzm4Xl5w6Zyl9GVXOKStwIDAQAB -----END PUBLIC KEY-----'
const encrypt = new JSEncrypt()
encrypt.setPublicKey(publicKey)

/*
* ============新增公司
* @params{
*   addCompanyVo:{
*       code:string         公司编码
*       name:string         公司名称
*       status:init         状态
*       whiteipaddress:string   IP白名单
*   }
* }
* */
export function addCompany(data) {
    return request({
        url: '/company/addCompany',
        method: 'post',
        data
    })
}

/*
*==============公司列表
* @params{
* searchCompanyVo:{    查询条件
*   name:string    公司名称
*   status:init    状态
* }
* searchByPageVo:{
*   page:init,
*   rows:init
* }
* */

export function searchByCondition(data) {
    return request({
        url: '/company/searchByCondition',
        method: 'post',
        data
    })
}

/*
* ============更新公司信息
* @params{
*  updateCompanyVo:{
*  id:
*  name:string    公司名称
*  status:init    状态
*
*  }
* }
* */
export function updateCompany(data) {
    return request({
        url: '/company/updateCompany',
        method: 'post',
        data
    })
}

//获取管理员ID

export function updateAdminBefore(data) {
    return request({
        url: '/company/updateAdminBefore',
        method: 'get',
        data
    })
}

//修改密码
export function updateSecret(data) {
    if (data.password) {
        let {password} = data
        password = encrypt.encrypt(password)
        password = encodeURIComponent(password)
        data.password = password
    }
    return request({
        url: '/company/updateSecret',
        method: 'post',
        data
    })
}
//获取key

export function getKey(data) {
    return request({
        url: '/confirmation/getKey',
        method: 'get',
        data
    })
}

