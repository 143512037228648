const chart = {
    state: {
        chartActiveItem: 'chartItem',
        memberActiveItem: 'memberInfo',
        drawer: false,
        chartMsg: [],
        charUserInfo: {
            // // appid: 124,
            // // userid: 75,
            // // sign: '8a0b7af6843b4807b77c04a53e108a3b',
            // touser: 30,
            // session: '8a0b7af6843b4807b77c04a53e108a3b',
            // orgi: 124,
            // username: '0000000',
            // nickname: '0000000',
            // msgtype: 'message',
            // attachmentid: '',
            // agentuser: 75
        },
        socket: null,
        refreshAmount: false,
        refreshOrder: false,//是否刷新订单（有订单状态改变或者新增订单刷新订单信息）
        chatList: [],//聊天列表
        groupData: [],//会员列表
        chartMemberInfo: {},//会员信息
        newsList: [],//新链接用户存表
    },
    mutations: {
        SET_DRAWER(state, drawer) {
            state.drawer = drawer
        },
        SET_CHART_ACTION_ITEM(state, item) {
            state.chartActiveItem = item
        },
        SET_MEMBER_ACTION_ITEM(state, item) {
            state.memberActiveItem = item
        },
        SET_CHARTMSG(state, msg) {
            state.chartMsg.push(msg)
        },
        SET_CHART_USERINFO(state, userInfo) {
            console.log(userInfo)
            state.charUserInfo = userInfo
        },
        SET_REFRESH_ORDER(state, status) {
            state.refreshOrder = !state.refreshOrder
        },
        SET_CHART_LIST(state, list) {
            state.chatList = list
        },
        SET_GROUP_DATA(state, group) {
            state.groupData = group
        },
        SET_REFRESH_AMOUNT(state, value) {
            state.refreshAmount = !state.refreshAmount
        },
        SET_MEMBER_INFO(state, value) {
            state.chartMemberInfo = value
        },
        SET_SOCKET: (state, socket) => {
            state.socket = socket
        },
        SET_NEW_LIST: (state, news) => {
            state.newsList = (news)
        },
        CLEARMSG(state) {
            state.chartMsg = []
        },
    },
    actions: {
        toggleChartActionItem({commit}, item) {
            commit('SET_CHART_ACTION_ITEM', item)
        },
        toggleMemberActionItem({commit}, item) {
            commit('SET_MEMBER_ACTION_ITEM', item)
        },
        toggleDrawer({commit}, drawer) {
            commit('SET_DRAWER', drawer)
        },
        setChartMsg({commit}, msg) {
            commit('SET_CHARTMSG', msg)
        },
        setChartUserInfo({commit}, userInfo) {
            commit('SET_CHART_USERINFO', userInfo)
        },
        setRefreshOrder({commit}, status) {
            commit('SET_REFRESH_ORDER', status)
        },
        setChartList({commit}, list) {
            commit('SET_CHART_LIST', list)
        },
        setGroupData({commit}, group) {
            commit('SET_GROUP_DATA', group)
        },
        setRefreshAmount({commit}, value) {
            commit('SET_REFRESH_AMOUNT', value)
        },
        setMemberInfo({commit}, value) {
            commit('SET_MEMBER_INFO', value)
        },
        setSocekt({commit}, socket) {
            commit('SET_SOCKET', socket)
        },
        setNewList({commit}, news){
            commit('SET_NEW_LIST', news)
        },
        clearMsg({commit}) {
            commit('CLEARMSG')
        }

    }
}
export default chart
