import Layout from '../layout'

const Catering = [
    {
        path: '/storeArea',
        name: 'storeArea',
        redirect: '/storeArea/storeAreaInfo',
        component: Layout,
        meta: {
            title: '餐点管理',
            icon: 'shouye',
        },
        children: [
            {
                path: 'storeAreaInfo',
                name: 'storeAreaInfo',
                meta: {
                    title: '餐点信息',
                    notCache: true,
                },
                component: () => import('../views/storeAreaManage/storeAreaInfo.vue')
            },
            {
                path: 'printerManage',
                name: 'printerManage',
                meta: {
                    title: '打印机管理',
                    notCache: true,
                },
                component: () => import('../views/storeAreaManage/printerManage.vue')
            }, {
                path: 'printerRecord',
                name: 'printerRecord',
                meta: {
                    title: '打印记录',
                    notCache: true,
                },
                component: () => import('../views/storeAreaManage/printerRecord.vue')
            }
        ]
    },
    {
        path: '/foodsManage',
        name: 'foodsManage',
        redirect: '/foodsManage/foodsType',
        component: Layout,
        meta: {
            title: '菜品管理',
            icon: 'shouye',
        },
        children: [
            {
                path: 'foodsType',
                name: 'foodsType',
                meta: {
                    title: '菜品类型',
                    notCache: true,
                },
                component: () => import('../views/foodsManage/foodsType.vue')
            },
            {
                path: 'foodsManageindex',
                name: 'foodsManageindex',
                meta: {
                    title: '菜品管理',
                    notCache: true,
                },
                component: () => import('../views/foodsManage/foodsManage.vue')
            }, {
                path: 'foodsLable',
                name: 'foodsLable',
                meta: {
                    title: '菜品标签',
                    notCache: true,
                },
                component: () => import('../views/foodsManage/foodsLabel.vue')
            }
        ]
    },
    {
        path: '/inventory',
        name: 'inventory',
        redirect: '/inventory/foodUnit',
        component: Layout,
        meta: {
            title: '库存管理',
            icon: 'shouye',
        },
        children: [
            {
                path: 'foodUnit',
                name: 'foodUnit',
                meta: {
                    title: '物料单位',
                    notCache: true,
                },
                component: () => import('../views/foodsManage/foodUnit.vue')
            },
            {
                path: 'foodMaterial',
                name: 'foodMaterial',
                meta: {
                    title: '物料类型',
                    notCache: true,
                },
                component: () => import('../views/foodsManage/foodMaterial.vue')
            },
            {
                path: 'foodMaterialManage',
                name: 'foodMaterialManage',
                meta: {
                    title: '库存管理',
                    notCache: true,
                },
                component: () => import('../views/foodsManage/foodMaterialManage.vue')
            },
            {
                path: 'materialManager',
                name: 'materialManager',
                meta: {
                    title: '库存订单',
                    notCache: true,
                },
                component: () => import('../views/foodsManage/materialManager.vue')
            },
            {
                path: 'foodStock',
                name: 'foodStock',
                meta: {
                    title: '库存盘点',
                    notCache: true,
                },
                component: () => import('../views/foodsManage/foodStock.vue')
            },
        ]
    },
    {
        path: '/orderManage',
        name: 'orderManage',
        redirect: '/orderManage/orderRecord',
        component: Layout,
        meta: {
            title: '订单管理',
            icon: 'shouye',
        },
        children: [
            {
                path: 'orderRecord',
                name: 'orderRecord',
                meta: {
                    title: '订单记录',
                    notCache: true,
                },
                component: () => import('../views/orderManage/orderRecord.vue')
            },
            {
                path: 'addressManage',
                name: 'addressManage',
                meta: {
                    title: '地址管理',
                    notCache: true,
                },
                component: () => import('../views/orderManage/addressManage.vue')
            }
        ]
    },
    {
        path: '/userManage',
        name: 'userManage',
        redirect: '/userManage/userManageindex',
        component: Layout,
        meta: {
            title: '用户管理',
            icon: 'shouye',
        },
        alwaysShow: true,
        children: [
            {
                path: 'userManageindex',
                name: 'userManageindex',
                meta: {
                    title: '用户管理',
                    notCache: true,
                },
                component: () => import('../views/userManage/userManage.vue')
            }
        ]
    },
    {
        path: '/advertManager',
        name: 'advertManager',
        redirect: '/advertManager/advertManagerIndex',
        component: Layout,
        meta: {
            title: '广告管理',
            icon: 'shouye',
        },
        children: [
            {
                path: 'advertManagerIndex',
                name: 'advertManagerIndex',
                meta: {
                    title: '广告管理',
                    notCache: true,
                },
                component: () => import('../views/advertManager/index.vue')
            },
            {
                path: 'advertType',
                name: 'advertType',
                meta: {
                    title: '广告类型',
                    notCache: true,
                },
                component: () => import('../views/advertManager/advertType.vue')
            }
        ]
    },
    {
        path: '/firmManage',
        name: 'firmManage',
        redirect: '/firmManage/firmManageindex',
        component: Layout,
        meta: {
            title: '商家管理',
            icon: 'shouye',
        },
        alwaysShow: true,
        children: [
            {
                path: 'firmManageindex',
                name: 'firmManageindex',
                meta: {
                    title: '网吧管理',
                    notCache: true,
                },
                component: () => import('../views/firmManage/firmManage.vue')
            },
            {
                path: 'returnPoint',
                name: 'returnPoint',
                meta: {
                    title: '商家返点',
                    notCache: true,
                },
                component: () => import('../views/firmManage/returnPoint.vue')
            }
        ]
    },
    {
        path: '/ticketManage',
        name: 'ticketManage',
        redirect: '/ticketManage/ticketManageIndex',
        component: Layout,
        meta: {
            title: '优惠券管理',
            icon: 'shouye',
        },
        alwaysShow: true,
        children: [
            {
                path: 'ticketManageIndex',
                name: 'ticketManageIndex',
                meta: {
                    title: '优惠券列表',
                    notCache: true,
                },
                component: () => import('../views/ticketManage/index.vue')
            },
            {
                path: 'getTicketHistory',
                name: 'getTicketHistory',
                meta: {
                    title: '领取记录',
                    notCache: true,
                },
                component: () => import('../views/ticketManage/getTicketHistory.vue')
            },
            {
                path: 'ticketDetail',
                name: 'ticketDetail',
                hidden:true,
                meta: {
                    title: '领取记录',
                    notCache: true,

                },
                component: () => import('../views/ticketManage/ticketDetail.vue')
            }
        ]
    },
    {
        path: '/activeManage',
        name: 'activeManage',
        redirect: '/activeManage/activityEvents',
        component: Layout,
        meta: {
            title: '活动管理',
            icon: 'shouye',
        },
        alwaysShow: true,
        children: [
            {
                path: 'activityEvents',
                name: 'activityEvents',
                meta: {
                    title: '活动赛事',
                    notCache: true,
                },
                component: () => import('../views/activeManage/activityEvents')
            },
            {
                path: 'gameNotice',
                name: 'gameNotice',
                meta: {
                    title: '赛事公告',
                    notCache: true,
                },
                component: () => import('../views/activeManage/gameNotice')
            },
            {
                path: 'activeManageIndex',
                name: 'activeManageIndex',
                meta: {
                    title: '活动列表',
                    notCache: true,
                },
                component: () => import('../views/activeManage/index.vue')
            },
            {
                path: 'luckyDrawhistory',
                name: 'luckyDrawhistory',
                meta: {
                    title: '抽奖记录',
                    notCache: true,
                },
                component: () => import('../views/activeManage/luckyDrawhistory.vue')
            },
            {
                path: 'shareHistory',
                name: 'shareHistory',
                meta: {
                    title: '分享记录',
                    notCache: true,
                },
                component: () => import('../views/activeManage/shareHistory.vue')
            },
        ]
    },

    {
        path: '/distributeManage',
        name: 'distributeManage',
        redirect: '/distributeManage/distributeManageIndex',
        component: Layout,
        meta: {
            title: '分销管理',
            icon: 'shouye',
        },
        alwaysShow: true,
        children: [
            {
                path: 'distributeManageIndex',
                name: 'distributeManageIndex',
                meta: {
                    title: '商家分销',
                    notCache: true,
                },
                component: () => import('../views/distributeManage/index.vue')
            },
            {
                path: 'config',
                name: 'config',
                meta: {
                    title: '分销配置',
                    notCache: true,
                },
                component: () => import('../views/distributeManage/config.vue')
            },
        ]
    },
    {
        path: '/gameStrategy',
        name: 'gameStrategy',
        redirect: '/gameStrategy/strategyOptions',
        component: Layout,
        meta: {
            title: '游戏攻略',
            notCache: true,
            icon: 'shouye',
        },
        alwaysShow:true,
        children: [
            {
                path: 'strategyOptions',
                name: 'strategyOptions',
                meta: {
                    title: '攻略配置',
                    notCache: true,
                },
                component: () => import('../views/gameStrategy/strategyOptions.vue')
            }
        ]
    },
    {
        path: '/rechargeCenter',
        name: 'rechargeCenter',
        redirect: '/rechargeManage/rechargeManageindex',
        component: Layout,
        meta: {
            title: '充值中心',
            notCache: true,
            icon: 'shouye',
        },
        alwaysShow:true,
        children: [
            {
                path: 'rechargeManageIndex',
                name: 'rechargeManageIndex',
                meta: {
                    title: '充值配置',
                    notCache: true,
                },
                component: () => import('../views/rechargeManage/index.vue')
            }
        ]
    },

]
export default Catering
