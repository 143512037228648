import router from './router'
import store from './store'
// import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import {isEmpty} from '@/utils/tools'
import 'nprogress/nprogress.css' // progress bar style

import getPageTitle from '@/utils/get-page-title'

NProgress.configure({showSpinner: false}) // NProgress Configuration
// permission judge function
function hasPermission(roles, permissionRoles) {
    roles += ''
    roles = roles.split('')
    if (!permissionRoles) return true
    return roles.some(role => permissionRoles.indexOf(role) >= 0)
}

const whiteList = ['/login', '/collapse', '/test','fileUpload']
router.beforeEach(async (to, from, next) => {
    // start progress bar
    NProgress.start()
    if (store.getters.token) {
        if (to.path === '/login') {
            next('/')
        } else {
            let addRouters = store.getters.addRouters
            if (!addRouters.length) {
                try {
                    let data = await store.dispatch('getMenu')
                    await store.dispatch('GenerateRoutes', data)
                    router.addRoutes(store.getters.addRouters)
                    next({...to, replace: true})
                } catch (e) {
                    // next('404')
                    console.log(e)
                    // debugger
                    next({path: '/403',replace: true})
                }
            } else {
                next()
                //     // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
                //     // if (hasPermission(role, to.meta.roles)) {
                //     //     next()
                //     // } else {
                //     //     next({path: '/401', replace: true, query: {noGoBack: true}})
                //     // }
            }

        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next('/login')

        }
    }
})

router.afterEach((to, form) => {
    document.title = getPageTitle(to.meta.title)
    // finish progress bar
    NProgress.done()
})
