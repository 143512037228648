import {isEmpty} from '@/utils/tools'

function formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
}

export function formartDate(number, format = 'Y-M-D h:m') {
    if (!number) return null
    let formateArr = ['Y', 'M', 'D', 'h', 'm', 's']
    let returnArr = []
    let date = new Date(number * 1000)
    returnArr.push(date.getFullYear())
    returnArr.push(formatNumber(date.getMonth() + 1))
    returnArr.push(formatNumber(date.getDate()))
    returnArr.push(formatNumber(date.getHours()))
    returnArr.push(formatNumber(date.getMinutes()))
    returnArr.push(formatNumber(date.getSeconds()))
    for (let i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i])
    }
    return format
}

export function formartTime_1(t) {
    if (!t) return '00:00:00'
    let full_h = t / 60 / 60
    let h = parseInt(full_h) || '00'
    let full_m = (full_h - h) * 60
    let m = parseInt(full_m) > 9 ? parseInt(full_m) : '0' + parseInt(full_m)
    let init_m = parseInt(full_m)
    let s = (t - h * 60 * 60 - init_m * 60) > 9 ? (t - h * 60 * 60 - init_m * 60) : '0' + (t - h * 60 * 60 - init_m * 60)
    let times = h + ':' + m + ':' + s
    return times
}

export function formartTime_2(t) {
    let full_h = t / 60 / 60
    let h = parseInt(full_h) || 0
    let full_m = (full_h - h) * 60
    let m = parseInt(full_m)
    let s = t - h * 60 * 60 - m * 60
    return (h) ? (h + '小时' + m + '分' + s + '秒') : (m) ? (m + '分' + s + '秒') : s + '秒'
}

export function formartTime_3(number, format = 'Y-M-D h:m:s') {
    let formateArr = ['Y', 'M', 'D', 'h', 'm', 's']
    let returnArr = []
    let time = getDate(number * 1000)
    returnArr.push(time.getFullYear())
    returnArr.push(formatNumber(time.getMonth() + 1))
    returnArr.push(formatNumber(time.getDate()))
    returnArr.push(formatNumber(time.getHours()))
    returnArr.push(formatNumber(time.getMinutes()))
    returnArr.push(formatNumber(time.getSeconds()))
    for (let i = 0; i < returnArr.length; i++) {
        format = format.replace(formateArr[i], returnArr[i])
    }
    return format
    // return number;
}

export function filterPrice(val) {
    if (isEmpty(val)) return '-'
    return (val / 100).toFixed(2) + '元'
}

export function formartTime_4(t) {
    let full_h = t / 60 / 60
    let h = parseInt(full_h) || 0
    let full_m = (full_h - h) * 60
    let m = Math.ceil(full_m)
    // let s = t - h * 60 * 60 - m * 60
    return (h) ? (h + '小时' + m + '分钟') : (m + '分钟')
}


//订单状态
export const orderStatus = [
    {
        label: '全部',
        value: -1
    },
    {
        label: '发起支付',
        value: 1,
        color: '#ff8e54'
    },
    {
        label: '支付成功',
        value: 2,
        color: '#5ecc62'
    },
    {
        label: '关闭支付',
        value: 3,
        color: '#c0c4cc'
    }
]
export  const paymentTypes=[
    {
        label: '支付宝',
        value: 1,
        icon:'zhifubao',
    },
    {
        label: '微信',
        value: 2,
        icon:'weixin'
    },
    {
        label: '银行卡',
        value: 3,
        icon:'yunsanfu'
    },
    {
        label: '云闪付',
        value: 4,
        icon:'yunsanfu'
    }
]
export const paymentTypeOptions = [
    {
        label: '全部',
        value: -1
    },
    {
        label: '支付宝',
        value: 1,
        icon:'zhifubao',
    },
    {
        label: '微信',
        value: 2,
        icon:'weixin'
    },
    {
        label: '银行卡',
        value: 3,
        icon:'yunsanfu'
    },
    {
        label: '云闪付',
        value: 4,
        icon:'yunsanfu'
    }
]
//chart 聊天状态 INSERVICE, INQUENE, END  服务中，排队中，结束
export const chartStatusOptions = [
    {
        label: '服务中',
        value: 'inservice'
    },
    {
        label: '排队中',
        value: 'inquene'
    },
    {
        label: '结束',
        value: 'end'
    },
]
