<template>
    <el-dialog class="form-dialog"
               :fullscreen="fullscreen"
               :close-on-click-modal="false"
               :show-close="false"
               :visible.sync="dialogVisible"
               :width="width"
               @opened="opened"
               :before-close="handleClose">
        <div slot="title" class="header-wrapper">
            <span>{{title}}</span>
            <i class="cw-icon cw-icon-guanbi-danchuang  dialog-close-btn" @click="handleClose"></i>
        </div>
        <slot name="content" >
        </slot>

    </el-dialog>
</template>

<script>
    export default {
        name: "formDialog",
        props: {
            dialogVisible: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: "标题"
            },
            width:{
                type:String,
                default:'456px'
            },
            fullscreen:{
                type:Boolean,
                default:false
            }
        },
        data() {
            return {}
        },
        methods: {
            opened(){
                this.$emit('opened')
            },
            handleClose() {
                this.$emit('close')
            },
            handleCancle() {
                this.$emit('cancel')
            },
            handleOk() {
                this.$emit('ok')
            }
        },
        created() {
        },
        mounted() {
        }
    }
</script>

<style lang="less">
    .form-dialog {
        .el-dialog__header {
            height: 62px;
            padding: 0 40px;
            display: flex;
            align-items: center;
            background-color: #f5f7fa;
            color: #606366;
            .header-wrapper {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #606266;
                .dialog-close-btn {
                    cursor: pointer;
                }
            }
        }
        .el-dialog__body {
            /*padding: 40px;*/
        }
    }

</style>
