export function isEmpty (obj) {
  // 检验 undefined 和 null
  if (!obj && obj !== 0 && obj !== '') {
    return true
  }

  if (Array.prototype.isPrototypeOf(obj) && obj.length === 0) {
    return true
  }

  if (Object.prototype.isPrototypeOf(obj) && Object.keys(obj).length === 0) {
    return true
  }
  if (typeof obj === 'string' && obj.length === 0) {
    return true
  }
  return false
}
/*
*功能： 模拟form表单的提交
*参数： URL 跳转地址 PARAMTERS 参数
*/
export function formPost (URL, PARAMTERS, method = 'post') {
  // 创建form表单
  let temp_form = document.createElement('form')
  temp_form.action = URL
  // 如需打开新窗口，form的target属性要设置为'_blank'
  temp_form.target = '_self'
  temp_form.method = method
  temp_form.style.display = 'none'
  // 添加参数
  for (let item in PARAMTERS) {
    let opt = document.createElement('textarea')
    opt.name = item
    opt.value = PARAMTERS[item]
    temp_form.appendChild(opt)
  }
  document.body.appendChild(temp_form)
  // 提交数据
  temp_form.submit()
}


export function colorFilter (type, status) {
  if (type === 'order') {
    if ([3, 4, 7, 11, 14, 16].indexOf(status) > -1) {
      return 'danger'
    } else if ([5, 8, 12, 17].indexOf(status) > -1) {
      return 'success'
    } else {
      return 'primary'
    }
  }
}

export function numFixed (num, len = 2) {
  return Number(num.toFixed(len))
}

export function deepClone (obj) {
  let result = Array.isArray(obj) ? [] : {}
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        result[key] = deepClone(obj[key]) // 递归复制
      } else {
        result[key] = obj[key]
      }
    }
  }
  return result
}

export function parseTimeRange (timeRange) {
  let query = []
  if (timeRange && (timeRange.constructor === Array) && !isEmpty(timeRange[0]) && !isEmpty(timeRange[1])) {
    query = deepClone(timeRange)
    query[0] = parseInt(timeRange[0] / 1000)
    query[1] = parseInt(timeRange[1] / 1000 + 86399)
    return query
  }
}
