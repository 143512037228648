import {asyncRouterMap, constantRouterMap,adminHome,shopHome} from '../../router'

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param roles
 */
function hasName(route, nav) {
    if (route.meta && route.meta.title) {

        return route.name === nav.pathName
    } else {
        return false
    }
}

function filterAsyncRouter(asyncRouterMap, navList) {
    let accessedRouters = []
    for (let i = 0; i < navList.length; i++) {
        let newRouterMap = asyncRouterMap.filter(route => {
            if (hasName(route, navList[i])) {
                if (route.children && route.children.length && navList[i].children ) {
                    route.children = filterAsyncRouter(route.children, navList[i].children)
                }
                return true
            } else {
                return false
            }
        })
        accessedRouters = accessedRouters.concat(newRouterMap)
    }
    return accessedRouters
}

const permission = {
    state: {
        routers: constantRouterMap,
        addRouters: []
    },
    mutations: {
        SET_ROUTERS: (state, routers) => {
            state.addRouters = routers
            state.routers = constantRouterMap.concat(routers)
        }
    },
    actions: {
        GenerateRoutes({commit}, menus) {
            return new Promise(resolve => {
                let menusArray = menus.filter(menu => menu.type === 'menu' && menu.pathName)
                let accessedRouters = filterAsyncRouter(asyncRouterMap, menusArray)
                //  判定是否为网吧管理员
                let internet = menusArray.filter(menu => menu.name === "网吧")
                if(internet.length){
                    accessedRouters.unshift(shopHome)
                }else{
                    accessedRouters.unshift(adminHome)
                }
                // accessedRouters.
                console.log('home',internet)
                console.log('menusArray',menusArray)
                // let homeChildName = home[0].children[0].pathName
                // if(homeChildName==='dashboardIndex'){
                //
                // }else {
                //
                // }
                commit('SET_ROUTERS', accessedRouters)
                resolve()
            })
        }
    }
}

export default permission
