const getters = {
    //app全局控制
    sidebar: state => state.app.sidebar,
    device: state => state.app.device,
    //用户信息
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    userInfo: state => state.user.userInfo,
    menu:state => state.user.menu,
    //聊天
    chartActiveItem:state=>state.chart.chartActiveItem,
    memberActiveItem:state=>state.chart.memberActiveItem,
    drawer:state=>state.chart.drawer,
    chartMsg:state=>state.chart.chartMsg,
    charUserInfo:state=>state.chart.charUserInfo,
    refreshOrder:state=>state.chart.refreshOrder,
    chatList:state=>state.chart.chatList,
    groupData:state=>state.chart.groupData,
    refreshAmount:state=>state.chart.refreshAmount,
    chartMemberInfo:state=>state.chart.chartMemberInfo,
    socket:state=>state.chart.socket,
    newsList:state=>state.chart.newsList,
    //路由
    addRouters:state=>state.permission.addRouters,
    permission_routers:state=>state.permission.routers,
}
export default getters
