import request from '../utils/request'

export function login(data) {
    return request({
        url: '/platform/login/doLogin',
        method: 'post',
        isReturn:true,
        data
    })
}

//获取用户信息
export function getUserMenu() {
    return request({
        url: '/platform/sys/user/menus',
        method: 'post'
    })
}


//退出
export function logout() {
    return request({
        url: '/login/logout',
        method: 'post'
    })
}

//上传图片

export function ploadFile(data) {
    return request({
        url: 'open/file/upload/image',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data;charse=UTF-8'
        },
        data
    })
}


//上传文件
export function uploadFile(data) {
    return request({
        url:'/fastdfs/uploadFile',
        method: 'post',
        isReturn:true,
        data
    })
}
//上传图片
export function uploadImg(form) {
    return request({
        url: '/open/file/upload/image',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data;charse=UTF-8'
        },
        data: form
    })
}
