import Layout from '../layout'

const System = [
    {
        path:'/msgCenter',
        name:'msgCenter',
        redirect:'/msgCenter/msgIndex',
        meta: {
            title: '消息中心',
            icon: 'gongsi',
            roles: ['1']
        },
        alwaysShow: true,
        component: Layout,
        children: [
            {
                path: 'msgIndex',
                name: 'msgIndex',
                meta: {
                    title: '全部消息',
                    // roles: ['1']
                },
                component: () => import('../views/msgCenter/msgAll.vue')
            },
            {
                path: 'msgUnRead',
                name: 'msgUnRead',
                meta: {
                    title: '未读消息',
                    // roles: ['1']
                },
                component: () => import('../views/msgCenter/unRead.vue')
            },
            {
                path: 'msgRead',
                name: 'msgRead',
                meta: {
                    title: '已读消息',
                    // roles: ['1']
                },
                component: () => import('../views/msgCenter/read.vue')
            }
        ]

    },
    {
        path:'/system',
        name:'system',
        redirect:'/system/sysIndex',
        meta: {
            title: '系统管理',
            icon: 'gongsi',
            roles: ['1']
        },
        alwaysShow: true,
        component: Layout,
        children: [
            {
                path: 'sysIndex',
                name: 'sysIndex',
                meta: {
                    title: '单位管理',
                    // roles: ['1']
                },
                component: () => import('../views/system/unit.vue')
            },
            {
                path: 'sysUser',
                name: 'sysUser',
                meta: {
                    title: '用户管理',
                    // roles: ['1']
                },
                component: () => import('../views/system/user.vue')
            },
            {
                path: 'sysRoles',
                name: 'sysRoles',
                meta: {
                    title: '角色管理',
                    // roles: ['1']
                },
                component: () => import('../views/system/role.vue')
            },
            {
                path: 'sysMenu',
                name: 'sysMenu',
                meta: {
                    title: '菜单管理',
                    // roles: ['1']
                },
                component: () => import('../views/system/menu.vue')
            },
            {
                path: 'sysParames',
                name: 'sysParames',
                meta: {
                    title: '系统参数',
                    // roles: ['1']
                },
                component: () => import('../views/system/parames.vue')
            },
            {
                path: 'sysLogs',
                name: 'sysLogs',
                meta: {
                    title: '日志管理',
                    // roles: ['1']
                },
                component: () => import('../views/system/logs.vue')
            },
            {
                path: 'sysSettime',
                name: 'sysSettime',
                meta: {
                    title: '定时任务',
                    // roles: ['1']
                },
                component: () => import('../views/system/setTime.vue')
            },
            {
                path: 'sysRouter',
                name: 'sysRouter',
                meta: {
                    title: '自定义路由',
                    // roles: ['1']
                },
                component: () => import('../views/system/routers.vue')
            },
            {
                path: 'sysMsg',
                name: 'sysMsg',
                meta: {
                    title: '消息管理',
                    // roles: ['1']
                },
                component: () => import('../views/system/msg.vue')
            },
        ]

    },
    {
        path:'/sysConfig',
        name:'sysConfig',
        redirect:'/sysConfig/sysConfigIndex',
        meta: {
            title: '系统配置',
            icon: 'gongsi',
            roles: ['1']
        },
        alwaysShow: true,
        component: Layout,
        children: [
            {
                path: 'sysConfigIndex',
                name: 'sysConfigIndex',
                meta: {
                    title: '数据字典',
                    // roles: ['1']
                },
                component: () => import('../views/sysConfig/dictionary.vue')
            },
            {
                path: 'secretKey',
                name: 'secretKey',
                meta: {
                    title: '秘钥管理',
                    // roles: ['1']
                },
                component: () => import('../views/sysConfig/secretKey.vue')
            }
        ]

    },

]
export default System
