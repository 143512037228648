<template>
    <div class="navbar">
        <div class="left-wrap">
            <Hamburger class="hamburger-container" :is-active="sidebar.opened" @toggleClick="toggleSideBar"/>
            <!--<el-button size="text" class="back-btn">后退</el-button>-->
            <Breadcrumb class="breadcrumb-container"/>
        </div>
        <div class="right-wrap">
            <el-dropdown trigger="click">
                <div class="el-dropdown-link">
                    {{userInfo.loginname||'超级管理员'}} <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="openCharge">支付密码</el-dropdown-item>
                    <el-dropdown-item @click.native="openPassword">修改密码</el-dropdown-item>
                    <el-dropdown-item @click.native="handleLogout">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <key-dialog @close="handleClose" :title="title" :dialogVisible="passwordDialog">
            <template slot="content">
                <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="100px">
                    <el-form-item label="旧密码" prop="oldPassword">
                        <el-input type="password" v-model="formData.oldPassword"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="newPassword">
                        <el-input type="password" v-model="formData.newPassword"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">确定修改</el-button>
                    </el-form-item>
                </el-form>
            </template>
        </key-dialog>
        <key-dialog @close="handleClose" :title="title" :dialogVisible="chargeDialog">
          <template slot="content">
            <el-form :model="formCharge" :rules="rulesCharge" ref="payForm" label-width="100px">
              <el-form-item label="旧密码" prop="oldPassword" v-if="userInfo.hasPayPassword>0">
                <el-input type="password" v-model="formCharge.oldPassword" placeholder="请输入旧支付密码"></el-input>
              </el-form-item>
              <el-form-item label="新密码" prop="newPassword">
                <el-input type="password" v-model="formCharge.newPassword" placeholder="请输入新支付密码"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitCharge('payForm')">确定设置</el-button>
              </el-form-item>
            </el-form>
          </template>
        </key-dialog>
    </div>
</template>

<script>
    import Hamburger from './Hamburger.vue'
    import Breadcrumb from './Breadcrumb.vue'
    import {mapActions, mapGetters} from 'vuex'
    import {getKey} from "../../api/company";
    import keyDialog from '../../components/formDialog'
    import {getUserInfo} from "../../utils/auth";
    import {doChangePassword,doChangeChargePassword} from "../../api/sysUser";

    export default {
        name: "Navbar",
        components: {
            Hamburger, Breadcrumb, keyDialog
        },
        data() {
            return {
                title: "公司Key",
                passwordDialog: false,
                chargeDialog: false,
                companyKey: '',
                userInfo: {},
                rules: {
                    oldPassword: [
                        {required: true, message: '请输入旧密码', trigger: ['change', 'blur']}
                    ],
                    newPassword: [
                        {required: true, message: '请输入新密码', trigger: ['change', 'blur']}
                    ]
                },
                rulesCharge: {
                  oldPassword: [
                    {required: true,message: '请输入旧密码', trigger: ['change', 'blur']}
                  ],
                  newPassword: [
                    {required: true, message: '请输入6-20位支付密码', trigger: ['change', 'blur'],min:6,max:20}
                  ],

                },
                formData: {
                    oldPassword: '',
                    newPassword: ''
                },
                formCharge: {
                  oldPassword: '',
                  newPassword: ''
                },

            }
        },
        computed: {
            ...mapGetters([
                'sidebar'
            ])
        },
        methods: {
            ...mapActions(['toggleSideBar', 'logout']),
            openPassword() {
                this.title = "修改密码"
                this.passwordDialog = true
            },
            openCharge() {
              this.title = "设置支付密码";
              this.chargeDialog = true;
              this.formCharge={};
              if(this.$refs['payForm'])
              this.$refs['payForm'].resetFields();
            },
            submitForm(formName) {
                this.$refs[formName].validate(async (valid) => {
                    if (valid) {
                        try {
                            let $this = this;
                            await doChangePassword(this.formData)
                            this.$alert('密码修改成功,请重新登录', '提示', {
                                async callback() {
                                    await $this.logout()
                                    window.location.reload()
                                }
                            })
                        } catch (e) {

                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            submitCharge(formName) {
              this.$refs[formName].validate(async (valid) => {
                if (valid) {
                  try {
                    let $this = this;
                    if(this.userInfo.hasPayPassword<0){
                      this.formCharge.oldPassword=this.formCharge.newPassword;
                    }
                    await doChangeChargePassword(this.formCharge);
                    this.$message({
                      message: '操作成功',
                      type: 'success'
                    });
                    this.userInfo.hasPayPassword=1;
                    window.localStorage.setItem('User',JSON.stringify(this.userInfo));
                    this.chargeDialog = false;


                    // let User
                  } catch (e) {

                  }
                } else {
                  console.log('error submit!!');
                  return false;
                }
              });
            },
              async handleGetKey() {
                  try {
                      let formData = {
                          companyCode: this.userInfo.companyCode
                      }
                      let {data} = await getKey(formData)
                      this.companyKey = data
                      this.passwordDialog = true;

                  } catch (e) {
                      console.error(e)
                  }
              },
            handleClose() {
                this.passwordDialog = false;
                this.chargeDialog = false
            },
            async handleCopy() {
                try {
                    this.$copyText(this.companyKey)
                    this.$message({
                        type: 'success',
                        message: "复制成功"
                    })
                    this.passwordDialog = false
                } catch (e) {
                    console.log(e)
                    this.$message({
                        type: 'error',
                        message: "复制失败"
                    })
                }

            },
            async handleLogout() {
                this.$confirm('您将退出登录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    try {
                        await this.logout()
                        window.location.reload()
                    } catch (e) {
                        console.log(e)
                        window.location.reload()
                    }
                }).catch(() => {

                });

            }
        },
        mounted() {
            let userInfo = getUserInfo()
            this.userInfo = userInfo ? JSON.parse(userInfo) : {}
            console.log(214,this.userInfo.hasPayPassword)
        }

    }
</script>

<style lang="less">
    .navbar {
        height: 60px;
        background-color: #ffffff;
        color: #606266;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left-wrap {
            display: flex;
            align-items: center;
        }
        .right-wrap {
            padding-right: 20px;
            .el-icon-arrow-down:before {
                content: "\e650";
            }
        }
        .hamburger-container {
            display: block;
            margin: 0 30px;
        }
        .el-dropdown-link {
            cursor: pointer;
        }
        .back-btn {
            color: #909399;
            margin-right: 10px;
            font-weight: 400;
            font-size: 12px;
            &:hover {
                color: #53a5ff;
            }
        }
    }

</style>
