import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import ElementUI from 'element-ui'
import './assets/theme/index.css'
import './style/index.less'
import './assets/font/iconfont.css'
import moment from 'moment'
import App from './App'
import store from './store'
import router from './router'
import './permission' // permission control


import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
import * as filters from './filters' // global filters


Vue.filter('formatDate', (value, pattern = 'YYYY-MM-DD HH:mm:ss') => {
    return value ? moment(value).format(pattern) : '-'
})
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

Vue.use(ElementUI)
Vue.config.productionTip = false
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
