import request from '../utils/request'

//查询用户
export function getSysUserData(data) {
    return request({
        url:'/platform/sys/user/data',
        method:'post',
        data
    })
}
//查询角色
export function userMenu(id,data) {
    return request({
        url:`/platform/sys/user/menu/${id}`,
        method:'post',
        data
    })
}

//充值密码
export function resetPwd(id) {
    return request({
        url:`/platform/sys/user/resetPwd/${id}`,
        method:'post'
    })
}

export function userType(type,id) {
    return request({
        url:`/platform/sys/user/${type}/${id}`,
        method:'post'
    })
}
export function userEdit(id) {
    return request({
        url:`/platform/sys/user/edit/${id}`,
        method:'post'
    })
}
export function userDetel(id) {
    return request({
        url:`/platform/sys/user/delete/${id}`,
        method:'post'
    })
}
export function usersDetel(data) {
    return request({
        url:`/platform/sys/user/delete`,
        method:'post',
        data
    })
}
export function userAddDo(data) {
    return request({
        url:`/platform/sys/user/addDo`,
        method:'post',
        data
    })
}
export function doChangePassword(data) {
    return request({
        url:`/platform/sys/user/doChangePassword`,
        method:'post',
        data
    })
}
export function doChangeChargePassword(data) {
    return request({
        url:`/platform/sys/user/doChangePayPassword`,
        method:'post',
        data
    })
}
export function userEditDo(data) {
    return request({
        url:`/platform/sys/user/editDo`,
        method:'post',
        data
    })
}
//角色树结构
export function unitTree(data) {
    return request({
        url:'/platform/sys/unit/tree',
        method:'post',
        data
    })
}
