<template>
    <el-scrollbar class="sidebar-container" wrap-class="scrollbar-wrapper">
        <div class="avatar-wrapper">
            <img class="avatar-img" :src="userInfo.headimg||avatar" alt="">
            <p class="greetings" v-if="!isCollapse">{{goods}}</p>
        </div>
        <!--unique-opened-->
        <!--text-color="#ffffff"-->
        <!--background-color="#53a5ff"-->
        <!--active-text-color="#ffffff"-->
        <!--background-color="#53a5ff"-->
        <el-menu
                unique-opened
                :show-timeout="200"
                :default-active="$route.path"
                :collapse="isCollapse"
        >
            <SidebarItem :routes="permission_routers"></SidebarItem>
        </el-menu>

    </el-scrollbar>
</template>

<script>
    import {mapGetters} from 'vuex'
    import SidebarItem from './SidebarItem'
    import avatar from '../../../assets/avatar.png'
    export default {
        name: "Sidebar",
        components: {SidebarItem},
        data(){
            return{
                avatar
            }
        },
        computed: {
            ...mapGetters([
                'sidebar', 'permission_routers', 'userInfo'
            ]),
            routes() {
                return this.$router.options.routes
            },
            isCollapse() {
                console.log(this.sidebar.opened)
                return !this.sidebar.opened
            },
            goods() {
                let hour = new Date().getHours()
                if (hour < 6) {
                    return "凌晨好！"
                }
                else if (hour < 9) {
                    return "早上好！"
                }
                else if (hour < 12) {
                    return "上午好！"
                }
                else if (hour < 14) {
                    return "中午好！"
                }
                else if (hour < 17) {
                    return "下午好！"
                }
                else if (hour < 19) {
                    return "傍晚好！"
                }
                else if (hour < 22) {
                    return "晚上好！"
                }
                else {
                    return "夜里好！"
                }
            }
        },
        mounted() {

        }
    }
</script>

<style lang="less">
    .sidebar-container {
        transition: width 0.28s;
        /*width: 240px;*/
        position: relative;
        z-index: 1001;
        overflow: hidden;
        background-color: #53a5ff;
        flex-shrink: 0;
        //reset element-ui css
        .horizontal-collapse-transition {
            transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
        }
        .scrollbar-wrapper {
            overflow-x: hidden !important;
            .el-scrollbar__view {
                height: 100%;
            }
        }
        .is-horizontal {
            display: none;
        }
        .el-menu {
            border: none;
            // 设定菜单宽度
            &:not(.el-menu--collapse) {
                width: 200px;
            }
        }
        .avatar-wrapper {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            .avatar-img {
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 40px;

            }
            .greetings {
                margin-left: 20px;
                font-size: 16px;
                color: #ffffff;

            }
        }
    }

    .el-menu--vertical {
        .el-menu--popup-right-start {
            margin: 0;
        }
    }

    .hideSidebar {
        /*.navbar {*/
        /*left: 36px;*/
        /*}*/
        /*.main-container {*/
        /*margin-left: 36px;*/
        /*}*/
        /*.sidebar-container {*/
        /*width: 36px !important;*/
        /*}*/
        /*.tags-view-container {*/
        /*margin-left: 36px;*/
        /*}*/
        /*.submenu-title-noDropdown {*/
        /*padding-left: 10px !important;*/
        /*!*position: relative;*!*/
        /*.el-tooltip {*/
        /*padding: 0 10px !important;*/
        /*}*/
        /*}*/

        .el-submenu {
            overflow: hidden;
            & > .el-submenu__title {

                /*padding-left: 10px !important;*/
                .el-submenu__icon-arrow {
                    display: none;
                }

            }
        }
        .el-menu--collapse {
            width: 60px;
            .el-submenu {
                & > .el-submenu__title {
                    & > span {
                        height: 0;
                        width: 0;
                        overflow: hidden;
                        visibility: hidden;
                        display: inline-block;
                    }
                }
            }
            .cw-icon {
                font-size: 16px;
                transition: all .6s ease-out;
            }
        }
    }


</style>
